import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

/**
 * Import relevant page-specific modules and provide a way of initializing them on the fly
 * @module
 */
/** Save the HTML data attr used to refer to UIs to be loaded on the fly */
const UI = 'data-alamo-ui';
const TYPE = 'data-component-type';
const REACT_TYPE = 'react';
const REACT_GENERIC_TYPE = 'react-generic';
const WIDGET_TYPE = 'widget';
const CQ_TYPE = 'data-cq-type';

/**
 * This function is used to dynamically import JavaScript modules based off of the
 * `data-component-type` (widget, react or react-generic) on the DOM node that react is being attached to
 * (by ways of having a `data-alamo-ui` atribute).
 *
 * `data-component-type="widget"` is used to bind Vanilla JS to a DOM node/HTL component
 *    - This path is to bind basic JavaScript functionality to DOM via HTL, so not React code, but vanilla JS.
 * `data-component-type="react"` is used to bind a React component to a DOM node. This comes in two flavors:
 *    1. Controller (no `data-cq-type`): This path should be taken if you your react component has some logic needed or the
 *       React component needs some props passed to it _outside_ of redux.
 *    2. AEM Dialogues: When `data-cq-type` is set this will ensure that any relevant model.json data is passed to the React component
 * `data-component-type="react-generic"` is used to bind a React component to a DOM node without passing it any data from AEM.
 *    - This path should be taken if the React component does not need any props passed to it outside of Redux. It is a shortcut
 *      to cut down on the controller boilerplate needed to do a simple `ReactDOM.render`.
 *
 * @param {String} module - The name of the React module to be imported.
 * @param {String} type   - Either 'widget', 'react', or 'react-generic', this string controls where the module is
 *                          imported from. This value defaults to "React" where this funciton is called...
 *                          `domEl.getAttribute(TYPE) || 'react'`
 * @param {String} cqType - This value (passed down from the DOM node's `data-cq-type` attribute)
 *                          is only needed is a Component being passed data from an AEM component's dialogues
 *                          via model.json. When this is the case, the `data-alamo-ui` attribute's value is
 *                          IGNORED and this value is used as a key in a `key: component` mapping established
 *                          by Adobe's `MapTo` function. The presence of this value causes the `ReactEditableComponent`
 *                          to be dynamically imported and to in turn handle loading the React componet.
 * @returns {Promise}
 */
const importModule = (module, type = '', cqType = null) => {
  switch (type.toLowerCase()) {
    // Check if the DOM node that react is being initialized has a `data-component-type` attribute and use this to either
    // dynamically import a `widget`, `controller` or standard react component (`react-generic`).
    case REACT_TYPE:
      // If the DOM node that react is being attached to has a `data-cq-type` attribute then this is a component that
      // is being passed data from AEM though `ReactEditableComponent`. In this case the `data-alamo-ui` is IGNORED
      // and the `data-cq-type` is used to map React components to (path) strings via a component's export using @adobe's
      // `MapTo` HOC (in the associated index.js file -- @see `components/TitleList/index.js` for an example)
      if (cqType !== null) {
        return import(
          /* webpackChunkName: "ReactEditableComponent" */
          /* webpackExclude: /(__snapshots__|__tests__)/ */
          `../ReactEditableComponent`
        );
      }
      // If it _does not_ have a have a `data-cq-type` attribute, then the controller will be in charge of the `ReactDOM.render`
      return import(
        /* webpackChunkName: "[request]" */
        /* webpackExclude: /(__snapshots__|__tests__)/ */
        `../controllers/${module}`
      );

    // when `data-component-type="widget"`
    case WIDGET_TYPE:
      return import(
        /* webpackChunkName: "[request]" */
        /* webpackExclude: /(__snapshots__|__tests__)/ */
        `../widgets/${module}`
      );

    // when `data-component-type="react-generic"`
    case REACT_GENERIC_TYPE:
      return import(
        /* webpackChunkName: "[request]" */
        /* webpackExclude: /(__snapshots__|__tests__)/ */
        `../components/${module}`
      );

    // fallback, should never happen
    default:
      return { default: {} };
  }
};

/**
 * This function scrapes the DOM for any elements with a `data-alamo-ui` attribute (regardless of value),
 * dynamically imports their needed code and then initialize the script within the DOM.
 *
 * Note: this function is invoked from `main.js` but _not_ `spa.js`, which makes sense, but wanted to call it out.
 *
 * @param {Object} store A redux store shared by all ui modules.
 * @param {HTMLElement} [scope] An optional scope element to check for ui modules within.
 */
export function initAlamoUi(store, model, scope = document.body) {
  const nodeList = scope.querySelectorAll(`[${UI}]`);
  nodeList.forEach((domEl) => {
    importModule(domEl.getAttribute(UI), domEl.getAttribute(TYPE) || 'react', domEl.getAttribute(CQ_TYPE))
      .then((module) => {
        const { init, type } = module.default;
        // If the module that was dynamically imported has a `init` method, then we are looking at a controller or widget
        if (typeof init === 'function') {
          init(store, domEl, model);
        }
        // If the imported module is a function (react component)
        // or its type property is a function (connected components since react-redux 7.1 update),
        // then we just want to import it as a component wrapped with Redux store provider!
        else if (typeof module.default === 'function' || typeof type === 'function') {
          const Component = module.default;
          const root = createRoot(domEl);
          root.render(
            <Provider store={store}>
              <Component />
            </Provider>
          );
        }
      })
      /* eslint-disable-next-line no-console */
      .catch((err) => console.warn(err));
  });
}
