// TODO: turns out this is not actually getting used anywhere but we may want to refactor it
// to a web component to be used by all brands in the future
import './styles/hero.scss';

import React from 'react';
// import propTypes from './HeroPropTypes';
const Hero = () => <div>hello hero</div>;
// const Hero = ({ leftAsset, rightAsset, leftAlt, rightAlt, title }) => (
//   <section className='hero'>
//     <div className='hero__image hero__image--left'>
//       <img src={leftAsset} alt={leftAlt} />
//     </div>
//     <div className='hero__core'>
//       <h1>{title}</h1>
//     </div>
//     <div className='hero__image hero__image--right'>
//       <img src={rightAsset} alt={rightAlt} />
//     </div>
//   </section>
// );

// Hero.propTypes = propTypes;

export default Hero;
